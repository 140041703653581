import { ActionButton, ModalDialogConfirmation } from "@able/react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "index";
import { useRef } from "react";
import { updateUserStatus } from "services/api/identity-service";
import { ableUrl } from "utils/constants";

type Props = {
  userId: string;
  active: boolean;
};

export default function UpdateStatusModal({ userId, active }: Props) {
  const statusModalRef = useRef<HTMLDialogElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const mutation = useMutation({
    mutationFn: ({ id, isUserActive }: { id: string; isUserActive: boolean }) => updateUserStatus({ id, isUserActive }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["users", userId] }),
  });

  const targetStatus = active ? "block" : "unblock";

  return (
    <>
      <ModalDialogConfirmation
        developmentUrl={ableUrl}
        title={`Confirm user ${targetStatus}`}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        stackButtonOnVXS
        description={`You are about to ${targetStatus} this user. Are you sure you wish to continue?`}
        confirmButtonEvents={{
          onClick: () => mutation.mutate({ id: userId, isUserActive: !active }),
        }}
        cancelButtonEvents={{ onClick: () => statusModalRef.current?.close() }}
      >
        <ActionButton
          // @ts-ignore
          ref={buttonRef}
          element="button"
          label={active ? "Block" : "Unblock"}
          variant="MediumEmphasis"
          developmentUrl={ableUrl}
        />
      </ModalDialogConfirmation>
    </>
  );
}
