import { Grid, SkipLink } from "@able/react";
import { Navigation } from "components/Navigation";
import { Outlet } from "react-router-dom";
import { Breadcrumbs } from "../components/ui/Breadcrumbs";
import "./AppLayout.scss";

export const AppLayout = () => {
  return (
    <div className="app-layout-container">
      <SkipLink contentId="#content" />
      <Navigation />
      <Breadcrumbs />
      <Grid>
        <Outlet />
      </Grid>
    </div>
  );
};
