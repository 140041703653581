import { Icon, MessageInline } from "@able/react";
import { ableUrl } from "utils/constants";
import { formatters } from "utils/formatters/formatters";
import "./styles.scss";

export const StatusMessage = ({ state, targetState }: { state: string; targetState: string }) => {
  const currentState = formatters.state(state, targetState);

  const isTransitioning = currentState?.includes("Pending");
  const isActive = currentState === "Active";
  const isBlocked = currentState === "Blocked";

  return (
    <>
      <div className="ss-message">
        {isActive && <Icon size="24" icon="Success" developmentUrl={ableUrl} />}
        {isBlocked && <Icon size="24" icon="Blocked" developmentUrl={ableUrl} />}
        {isTransitioning && <Icon size="24" icon="InProgress" developmentUrl={ableUrl} />}
        <p className="body18">
          <span className="visually-hidden">The service status is currently,</span>
          {currentState}
        </p>
      </div>
      {isActive && <p className="p-no-bottom-m">Select "Block" to pause the service.</p>}
      {isBlocked && <p className="p-no-bottom-m">Select "Unlock" to re-active the service.</p>}
      {isTransitioning && (
        <MessageInline
          variant="Information"
          description="You've requested to update the service status. Changes will be available within 24 hours. Please contact your Telstra representative for more information."
          developmentUrl={ableUrl}
        />
      )}
    </>
  );
};
