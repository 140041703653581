import { Icon } from "@able/react";
import { Avatar } from "components/Avatar/Avatar";
import { PageHeading } from "components/ui/PageHeading";
import { Helmet } from "react-helmet-async";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { ableUrl } from "utils/constants";
import "./HelpContainer.scss";

export const HelpContainer = () => {
  useBreadCrumbs([{ to: "/", label: "Dashboard" }]);

  return (
    <main>
      <Helmet>
        <title>Help and Support - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Help and support" />
      <p className="page-description">
        I'm here to help you with any questions you have about your dedicated network services.
      </p>

      <div className="help-content">
        <Avatar size="96" name="Luke Taylor" mode="light" randomColor={false} />
        <div>
          <h2>Luke Taylor</h2>
          <p>Account Manager</p>
          <dl>
            <div className="list-grouping">
              <dt>
                <Icon
                  icon="MapMarker"
                  size="32"
                  className="icon-styles"
                  developmentUrl={ableUrl}
                  screenReaderContent="Location"
                />
              </dt>
              <dd>Sydney</dd>
            </div>
            <div className="list-grouping">
              <dt>
                <Icon
                  icon="Mail"
                  size="32"
                  className="icon-styles"
                  developmentUrl={ableUrl}
                  screenReaderContent="Email"
                />
              </dt>
              <dd>luke.taylor@team.telstra.com</dd>
            </div>
            <div className="list-grouping">
              <dt>
                <Icon
                  icon="Mobile"
                  size="32"
                  className="icon-styles"
                  developmentUrl={ableUrl}
                  screenReaderContent="Mobile Phone Number"
                />
              </dt>
              <dd>+61 438 060 232</dd>
            </div>
          </dl>
        </div>
      </div>
    </main>
  );
};
