import { ActionButton, ModalDialogConfirmationDestructive } from "@able/react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "index";
import { useRef } from "react";
import { deleteUser } from "services/api/identity-service";
import { ableUrl } from "utils/constants";

type Props = {
  userId: string;
  onSuccess?: () => void;
};

export default function DeleteUserModal({ userId, onSuccess }: Props) {
  const deleteModalRef = useRef<HTMLDialogElement>(null);

  const mutation = useMutation({
    mutationFn: (id: string) => deleteUser(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (onSuccess !== undefined) {
        onSuccess();
      }
    },
  });

  return (
    <>
      <ModalDialogConfirmationDestructive
        developmentUrl={ableUrl}
        title="Confirm user deletion"
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        stackButtonOnVXS
        description="You are about to permanently delete this user. Are you sure you wish to continue?"
        confirmButtonEvents={{ onClick: () => mutation.mutate(userId) }}
        cancelButtonEvents={{ onClick: () => deleteModalRef.current?.close() }}
      >
        <ActionButton
          element="button"
          label="Delete"
          variant="Destructive"
          deleteIcon={true}
          ariaDisabled={mutation.isPending}
          developmentUrl={ableUrl}
        />
      </ModalDialogConfirmationDestructive>
    </>
  );
}
