import { ProtectedRoutes } from "pages/ProtectedRoutes";
import { DashboardContainer } from "pages/dashboard/DashboardContainer";
import { HelpContainer } from "pages/help/HelpContainer";
import {
  MultiSectorContainer,
  SectorThroughput,
  ServiceAreaThroughput,
  SingleSectorContainer,
  ThroughputContainer,
  UtilisationContainer,
} from "pages/network";
import { NotFound } from "pages/not-found/NotFound";
import NotificationManagement, { ConfirmationScreen } from "pages/notification-management";
import { RecipientFormContainer } from "pages/notification-management/RecipientForm";
import { ConfigurationContainer, PerformanceContainer, ServiceContainer } from "pages/service";
import { CreateHybridServiceContainer } from "pages/services/components/CreateHybridServiceForm/CreateHybridServiceContainer";
import ServicesContainer from "pages/services/services-container";
import UserManagementContainer from "pages/user-management/UserManagementContainer";
import CreateUserPage from "pages/user-management/create/CreateUserPage";
import EditUserPage from "pages/user-management/edit/EditUserPage";
import { Navigate, createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      {
        index: true,
        element: <DashboardContainer />,
      },
      {
        path: "oauthCallback",
        element: <Navigate to={"/"} />,
      },
      {
        path: "help",
        element: <HelpContainer />,
      },
      {
        path: "network",
        children: [
          {
            path: "throughput",
            element: <ThroughputContainer />,
            children: [
              {
                path: "service-area",
                element: <ServiceAreaThroughput />,
              },
              {
                path: "sectors",
                element: <SectorThroughput />,
              },
            ],
          },
          {
            path: "utilisation",
            element: <UtilisationContainer />,
            children: [
              {
                path: "single-sector",
                element: <SingleSectorContainer />,
              },
              {
                path: "multi-sector",
                element: <MultiSectorContainer />,
              },
            ],
          },
        ],
      },
      {
        path: "services",
        element: <ServicesContainer />,
      },
      {
        path: "services/create",
        element: <CreateHybridServiceContainer />,
      },
      {
        path: "services/:id",
        element: <ServiceContainer />,
        children: [
          {
            path: "configuration",
            element: <ConfigurationContainer />,
          },
          {
            path: "performance",
            element: <PerformanceContainer />,
          },
        ],
      },
      {
        path: "notification-management",
        children: [
          {
            index: true,
            element: <NotificationManagement />,
          },
          {
            path: "create",
            element: <RecipientFormContainer template="Create" />,
          },
          {
            path: "confirmation",
            element: <ConfirmationScreen />,
          },
          {
            path: ":id",
            element: <div />,
          },
          {
            path: ":id/edit",
            element: <RecipientFormContainer template="Edit" />,
          },
        ],
      },
      {
        path: "user-management",
        children: [
          {
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: "create",
            element: <CreateUserPage />,
          },
          {
            path: ":id/edit",
            element: <EditUserPage />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
