import { ActionButton, MessageInline } from "@able/react";
import { PageHeading } from "components/ui/PageHeading";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import useUserQuery from "services/hooks/useUserQuery";
import { isAuthorised } from "utils";
import { ableUrl } from "utils/constants";
import { UserManagementTable } from "./UserManagementTable";

export default function UserManagementContainer() {
  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);
  const navigate = useNavigate();
  const authorised = isAuthorised("ADMIN");
  const { data, error, isLoading } = useUserQuery({ enabled: authorised, fetchOnWindowRefocus: false });

  useEffect(() => {
    let timeout: number;

    if (!authorised) {
      timeout = window.setTimeout(() => navigate("/"), 2000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [authorised, navigate]);

  if (!authorised) {
    return (
      <div>
        <MessageInline variant="Error" developmentUrl={ableUrl}>
          User it not permitted to view this information! User will be redirected shortly.
        </MessageInline>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <MessageInline variant="Error" developmentUrl={ableUrl}>
          {error.message}
        </MessageInline>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <p>Loading users...</p>
      </div>
    );
  }

  return (
    <main>
      <Helmet>
        <title>User management - Telstra Dedicated Networks</title>
      </Helmet>
      <PageHeading content="User management" />
      <div className="user-button-group">
        <ActionButton element="Link" href="create" label="Create new user" variant="MediumEmphasis" />
      </div>
      <UserManagementTable userData={data} />
    </main>
  );
}
