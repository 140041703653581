import { AxiosRequestConfig } from "axios";
import { Subscription } from "types";
import axiosInstance from "utils/axiosInstance";

export async function fetchSubscriptions(tenancyId: string, options?: AxiosRequestConfig) {
  const response = await axiosInstance.get("/api/tdn/inventory/v1/subscriptions", {
    params: { tenancyId: tenancyId },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function fetchSubscriptionById(subscriptionId: string | undefined | null, options?: AxiosRequestConfig) {
  const response = await axiosInstance.get(`/api/tdn/inventory/v1/subscriptions/${subscriptionId}`, options);

  const data = response.data?.data;
  return data;
}

export async function fetchInventoryCount(tenancyId: string, options?: AxiosRequestConfig) {
  const response = await axiosInstance.get("/api/tdn/inventory/v1/subscriptions/service-tier/count", {
    params: { tenancyId: tenancyId },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

type TFieldEntries = Array<TFieldEntry>;

type TFieldEntry = [
  fieldName: string,
  fieldObj: {
    value: string;
    errorMsg?: boolean;
  },
];

export async function patchSubscriptionById(
  subscription: Subscription,
  fieldEntries: TFieldEntries,
  options?: AxiosRequestConfig
) {
  let operationsArray = [];

  for (const [fieldName, fieldObj] of fieldEntries) {
    let opObject = {
      op: "add",
      path: "/" + fieldName,
      value: fieldObj.value,
    };
    if (subscription.hasOwnProperty(fieldName)) {
      opObject.op = "replace";
    }
    operationsArray.push(opObject);
  }

  const response = await axiosInstance.patch(
    `/api/tdn/inventory/v1/subscriptions/${subscription.id}`,
    operationsArray,
    options
  );

  const data = response.data?.data;
  return data;
}

export async function activateSubscription(
  subscription: Subscription,
  isServiceAreaRestricted: boolean,
  serviceTier: string,
  options?: AxiosRequestConfig
) {
  const response = await axiosInstance.post(
    `/api/tdn/inventory/v1/subscriptions/${subscription.id}/activate`,
    {
      isServiceAreaRestricted,
      serviceTier,
    },
    options
  );

  const data = response.data?.data;
  return data;
}

export async function updateSubscriptionStateById(
  subscription: Subscription,
  eventType: string,
  options?: AxiosRequestConfig
) {
  const response = await axiosInstance.patch(
    `/api/tdn/inventory/v1/subscriptions/${subscription.id}/state/${eventType}`,
    undefined,
    options
  );

  const data = response.data?.data;
  return data;
}

export async function updateServiceTierById(
  subscription: Subscription,
  eventType: string,
  options?: AxiosRequestConfig
) {
  const response = await axiosInstance.patch(
    `/api/tdn/inventory/v1/subscriptions/${subscription.id}/service-tier/${eventType}`,
    undefined,
    options
  );

  const data = response.data?.data;
  return data;
}
