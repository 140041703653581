import { Divider } from "@able/react";
import { Logo } from "components/Logo";
import { BurgerButton, SignOutButton } from "components/ui/Buttons";
import { useOnNavigateOutside } from "hooks/useOnNavigateOutside";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { isAuthorised } from "utils";
import "./styles.scss";

type Props = {
  isOpen: boolean;
  drawerHandler: () => void;
};

export function Drawer({ isOpen = false, drawerHandler }: Props) {
  const drawerRef = useOnNavigateOutside<HTMLDivElement>({ isOpen, onNavigateOutside: drawerHandler });
  const burgerRef = useRef<HTMLButtonElement>(null);
  const authorised = isAuthorised("ADMIN");

  // Focus button when drawer opens
  useEffect(() => {
    if (isOpen && burgerRef && burgerRef.current) {
      burgerRef.current.focus();
    }
  }, [isOpen, burgerRef]);

  return (
    <>
      <div aria-hidden={isOpen} className={`background-overlay ${isOpen && "show-drawer"}`} />
      <div aria-hidden={isOpen} ref={drawerRef} className={`drawer-container ${isOpen ? "show-drawer" : ""}`}>
        <header className="drawer-header">
          <div className="logo-container">
            <Logo />
            <BurgerButton ref={burgerRef} isOpen={isOpen} onClickHandler={drawerHandler} />
          </div>
          <nav>
            <ol>
              <div className="mobile-nav">
                <li>
                  <Link to="/services" onClick={drawerHandler}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/network" onClick={drawerHandler}>
                    Network
                  </Link>
                </li>
              </div>
              <ol className="notification-management">
                <div>
                  <h2>Notification Management</h2>
                </div>
                <li>
                  <Link to="/notification-management/create" onClick={drawerHandler}>
                    Create a recipient
                  </Link>
                </li>
                <li>
                  <Link to="/notification-management" onClick={drawerHandler}>
                    Current recipients
                  </Link>
                </li>
              </ol>
              {authorised ? (
                <ol className="notification-management">
                  <div>
                    <h2>User Management</h2>
                  </div>
                  <li>
                    <Link to="/user-management" onClick={drawerHandler}>
                      Current users
                    </Link>
                  </li>
                </ol>
              ) : null}
              <Divider />
              <li>
                <Link to="/help" onClick={drawerHandler}>
                  Help and Support
                </Link>
              </li>
              <li>
                <SignOutButton />
              </li>
            </ol>
          </nav>
        </header>
      </div>
    </>
  );
}
