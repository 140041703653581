import { Icon, MessageInline } from "@able/react";
import { HyperLinkButton } from "components/ui/Buttons";

import { InfoTooltip } from "components/InfoTooltip";
import { queryClient } from "index";
import { ChangeEvent, useRef, useState } from "react";
import { updateServiceTierById } from "services/api/inventory-service";
import { Subscription } from "types";
import { ableUrl } from "utils/constants";
import { formatters } from "utils/formatters/formatters";
import { isAuthorised } from "utils/tokens";
import "./ServiceTier.scss";
import { ServiceTierRadioGroup } from "./ServiceTierRadioGroup";

export const ServiceTier = ({ subscription }: { subscription: Subscription }) => {
  const focusTargetRef = useRef<HTMLHeadingElement | null>(null);
  const initialServiceTier = subscription.serviceTier.value;
  const [serviceTier, setServiceTier] = useState<string>(initialServiceTier);
  const [isEditing, setIsEditing] = useState(false);

  const formattedServiceTier = formatters.serviceTier(subscription.serviceTier);
  const isActiveSub = subscription.state === "active";
  const isServiceTierProcessing = initialServiceTier === "processing";
  const showChangeOptions = isActiveSub && !isServiceTierProcessing;
  const authorised = isAuthorised("ADMIN");

  const onConfirmTierChange = () => {
    if (initialServiceTier === serviceTier) {
      // dispatchErrorToast("Service Tier not changed! Select and new tier or press 'cancel'.");
    } else {
      const event = String(serviceTier).toUpperCase();
      updateServiceTierById(subscription, event)
        .then(() => {
          setIsEditing(false);
          queryClient.invalidateQueries({ queryKey: ["subscription"] });
          if (focusTargetRef.current) {
            focusTargetRef.current.focus();
          }
        })
        .catch(() => {
          console.error("An error occurred while attempting service tier update.");
        });
    }
  };

  // Items are listed in the order they should appear in the DOM
  return (
    <div className="service-tier-container">
      {/* HEADING */}
      <div className="service-tier-heading">
        <h3 ref={focusTargetRef} tabIndex={-1} style={{ outlineColor: "transparent" }}>
          Add-on service tier
        </h3>
        <InfoTooltip
          id="service-tier-tooltip"
          label="Add-on service tier Information"
          description="The service tier defines the priority and minimum bandwidth of individual services that belong to it."
        />
      </div>

      {/* BUTTONG GROUP - LAST*/}
      {showChangeOptions && (
        <div className="service-tier-buttons">
          {isEditing ? (
            <>
              <HyperLinkButton
                aria-describedby="Hello"
                content="Cancel"
                onClick={() => {
                  setIsEditing(false);
                  setServiceTier(initialServiceTier);
                }}
              />
              <HyperLinkButton content="Confirm" onClick={onConfirmTierChange} />
            </>
          ) : (
            <HyperLinkButton content="Change" disabled={!authorised} onClick={() => setIsEditing(true)} />
          )}
        </div>
      )}

      {/* CONTENT */}
      <div className="service-tier-message">
        <div className="flex-aligncenter__gapped">
          {isServiceTierProcessing && <Icon icon="InProgress" />}
          <span className="visually-hidden">The service tier is currently,</span>
          <p style={{ margin: 0 }} className="body16">
            {formattedServiceTier.value}
          </p>
          <p style={{ margin: 0, fontSize: 14 }} className="body16">
            {formattedServiceTier.unit}
          </p>
        </div>
        {isEditing && (
          <>
            <MessageInline
              description="Changing the service tier speed may reduce minimum bandwidth of
                              all devices on the same tier, if the tier is approaching maximum capacity."
              variant="Warning"
              developmentUrl={ableUrl}
            />
            <p style={{ display: "inline-block", paddingRight: "1em" }}>DL = downlink</p>
            <p style={{ display: "inline-block" }}>UL = uplink</p>
            <br />
            <ServiceTierRadioGroup
              state={serviceTier}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setServiceTier(e.target.value)}
            />
          </>
        )}
        {/* MESSAGE GROUP */}
        {isServiceTierProcessing && (
          <MessageInline
            variant="Information"
            description="You've requested to change the service tier. Changes will be available within
                24 hours. Please contact your Telstra representative for more information."
            developmentUrl={ableUrl}
          />
        )}
      </div>
    </div>
  );
};
