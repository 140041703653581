import { ActionButton, MessageInline, Select } from "@able/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { User } from "types";
import { ableUrl } from "utils/constants";
import { z } from "zod";
import DeleteUserModal from "../components/modals/DeleteUserModal";
import UpdateStatusModal from "../components/modals/UpdateStatusModal";

const formSchema = z.object({
  role: z.string(),
});

export type FormFields = z.infer<typeof formSchema>;

type Props = {
  userData: User;
  onSubmit: (id: string, data: FormFields) => void;
};

export default function EditUserForm({ userData, onSubmit }: Props) {
  const navigate = useNavigate();
  const [showNoChangesMessage, setShowNoChangesMessage] = useState(false);
  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: { role: userData.roles[0].value.split(":")[1] ?? "USER" },
  });

  const errors = form.formState.errors;

  function onFormSubmission(formData: FormFields) {
    // if the form values haven't been changed then don't submi
    if (form.formState.isDirty) {
      onSubmit(userData.id, formData);
    } else {
      setShowNoChangesMessage(true);
    }
  }

  // Indicator to show if no fields have been changed in the frontend.
  useEffect(() => {
    if (form.formState.isDirty && showNoChangesMessage) {
      setShowNoChangesMessage(false);
    }
  }, [form.formState.isDirty, showNoChangesMessage]);

  return (
    <form className="flex-column gap-1rem" onSubmit={form.handleSubmit(onFormSubmission)}>
      {showNoChangesMessage ? (
        <MessageInline variant="Attention" description={"No values have been changed!"} developmentUrl={ableUrl} />
      ) : null}
      <Controller
        name="role"
        control={form.control}
        render={({ field: { onChange, ...rest } }) => (
          <Select
            id="role-field"
            label="User role*"
            helpText="Select a role for this user."
            developmentUrl={ableUrl}
            allowEmpty={false}
            options={[
              { label: "USER", value: "USER" },
              { label: "ADMIN", value: "ADMIN" },
            ]}
            invalid={!!errors.role?.message}
            events={{ onChange: onChange }} // Required to mitigate you passed a value prop without an onchange handler.
            {...rest}
          />
        )}
      />
      <div className="edit-form-button-group">
        <DeleteUserModal userId={userData.id} onSuccess={() => navigate("/user-management")} />
        <UpdateStatusModal userId={userData.id} active={userData.active} />
        <ActionButton element="button" label="Update" variant="HighEmphasis" type="submit" />
      </div>
    </form>
  );
}
