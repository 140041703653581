import { ActionButton, Spacing } from "@able/react";
import { Pagination } from "components/Table/Pagination";
import { Table } from "components/Table/Table";
import { TableBody } from "components/Table/TableBody";
import { TableDataCell } from "components/Table/TableDataCell";
import { TableFooter } from "components/Table/TableFooter";
import { TableHeader } from "components/Table/TableHeader";
import { TableHeaderCell } from "components/Table/TableHeaderCell";
import { TableRow } from "components/Table/TableRow";
import { useCallback, useEffect, useState } from "react";
import { User } from "types";
import { ableUrl } from "utils/constants";
import "./styles.scss";

type FormattedData = {
  id: string;
  email: string;
  privileges: string;
  status: string;
  active: boolean;
};

type TSortValues<Data> = {
  column: keyof Data | null;
  order: "none" | "ascending" | "descending";
};

type UserManagementProps = {
  userData: User[];
};

export function UserManagementTable({ userData }: UserManagementProps) {
  const [sort, setSort] = useState<TSortValues<FormattedData>>({ column: null, order: "none" });
  const [pagination, setPagination] = useState({ currentPage: 1, rowsPerPage: 5 });
  const [formattedRows, setFormattedRows] = useState<FormattedData[]>([]);

  // The actual sorting function. Wrapped in useCallback to avoid re-renders.
  const handleSort = useCallback(
    (formattedRows: FormattedData[], column: keyof FormattedData | null) => {
      if (column) {
        const sorted = [...formattedRows].sort((a: any, b: any) => {
          // This will return either a string value or undefined.
          const valA = a[column]?.toString().toLocaleLowerCase();
          const valB = b[column]?.toString().toLocaleLowerCase();

          if (valA === undefined || valA > valB) return sort.order === "ascending" ? 1 : -1;
          if (valB === undefined || valA < valB) return sort.order === "ascending" ? -1 : 1;
          return 0;
        });

        return sorted;
      }
      return formattedRows;
    },
    [sort.order]
  );

  function handleSortClick(sortColumn: keyof FormattedData) {
    setSort({
      column: sortColumn,
      order:
        sort.order === "none" || sort.column !== sortColumn
          ? "ascending"
          : sort.order === "ascending"
            ? "descending"
            : "ascending",
    });
  }

  useEffect(() => {
    const formattedRows: FormattedData[] = userData.map((item) => {
      return {
        id: item.id,
        email: item.userName,
        privileges: item.roles[0].value.split(":")[1],
        status: item.active ? "Active" : "Inactive",
        active: item.active,
      };
    });

    const sorted = handleSort(formattedRows, sort.column);
    setFormattedRows(sorted);
    // Reset pagination current Page to 1 to avoid incorrect slicing.
    setPagination((s) => ({ ...s, currentPage: 1 }));
  }, [userData, sort, handleSort]);

  // Pagination starts at the current page - 1 (normalize array) multiplied by number of rows allowed.
  const sliceStart = (pagination.currentPage - 1) * pagination.rowsPerPage;

  // Pagination end value is either sliceStart + rowsPerPage, or the end of (length of) the array, depends which is lesser.
  const sliceEnd =
    sliceStart + pagination.rowsPerPage < formattedRows.length
      ? sliceStart + pagination.rowsPerPage
      : formattedRows.length;

  const totalPages = Math.ceil(formattedRows.length / pagination.rowsPerPage);

  const tableMessage =
    formattedRows.length > 0
      ? `Showing results ${sliceStart + 1} - ${sliceEnd} of ${formattedRows.length}`
      : "No results to display.";

  return (
    <div>
      <Spacing bottom="spacing10x">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell
                isSortable
                sortOrder={sort.column === "email" ? sort.order : "none"}
                sortingCallback={() => handleSortClick("email")}
              >
                Email
              </TableHeaderCell>
              <TableHeaderCell
                isSortable
                sortOrder={sort.column === "status" ? sort.order : "none"}
                sortingCallback={() => handleSortClick("status")}
              >
                Status
              </TableHeaderCell>
              <TableHeaderCell
                isSortable
                sortOrder={sort.column === "privileges" ? sort.order : "none"}
                sortingCallback={() => handleSortClick("privileges")}
              >
                Privileges
              </TableHeaderCell>
              <TableHeaderCell>Actions</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {formattedRows.slice(sliceStart, sliceEnd).map((user, idx) => (
              <TableRow key={idx}>
                <TableDataCell>{user.email}</TableDataCell>
                <TableDataCell>{user.status}</TableDataCell>
                <TableDataCell>{user.privileges}</TableDataCell>
                <TableDataCell>
                  <div className="action-button-group">
                    <ActionButton
                      element="Link"
                      label="Edit"
                      variant="LowEmphasis"
                      href={`/user-management/${user.id}/edit`}
                      developmentUrl={ableUrl}
                      icon="Edit"
                    />
                  </div>
                </TableDataCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <p>{tableMessage}</p>
          {totalPages > 1 && (
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={totalPages}
              onPageChange={(page: number) => setPagination((s) => ({ ...s, currentPage: page }))}
            />
          )}
        </TableFooter>
      </Spacing>
    </div>
  );
}
