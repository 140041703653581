import { ActionButton, MessageInline, Spacing, Switch, TextField } from "@able/react";
import { zodResolver } from "@hookform/resolvers/zod";
import Subheading from "components/Forms/Subheading";
import { PageHeading } from "components/ui/PageHeading";
import { useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { ableUrl } from "utils/constants";
import { NrDeleteWarningModal } from "./NrDeleteWarningModal";
import { NrNoSubWarningModal } from "./NrNoSubWarningModal";
import "./RecipientForm.scss";
import { TRecipientFormValues, recipientFormSchema } from "./RecipientFormSchema";

type TRecipientFormProps = {
  template: "Create" | "Edit";
  formAction: (formData: TRecipientFormValues) => Promise<any>;
  defaultValues?: TRecipientFormValues;
  deleteAction?: () => Promise<any>;
};

export const RecipientForm = ({ template, formAction, defaultValues, deleteAction }: TRecipientFormProps) => {
  const [showNoSubWarning, setShowNoSubWarning] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const navigate = useNavigate(); // navigate away and send data
  const submitButtonRef = useRef(null);
  const deleteButtonRef = useRef(null);

  useBreadCrumbs([
    { label: "Dashboard", to: "/" },
    { label: "Notification Management", to: "/notification-management" },
  ]);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<TRecipientFormValues>({
    resolver: zodResolver(recipientFormSchema),
    defaultValues,
  });

  const navigateToConfirmation = () => {
    const { name, mobileNumber, email, isEmailEnabled, isSmsEnabled } = getValues();
    navigate("/notification-management/confirmation", {
      state: {
        status: "success",
        action: template === "Create" ? "added" : "updated",
        recipient: name,
        mobileNumber,
        email,
        isEmailEnabled,
        isSmsEnabled,
      },
    });
  };

  const formSubmit: SubmitHandler<TRecipientFormValues> = (data) => {
    // Add recipient without subscribing them to sms or email?
    if (!data.isEmailEnabled && !data.isSmsEnabled) {
      return setShowNoSubWarning(true);
    } else {
      formAction(data)
        .then(() => navigateToConfirmation())
        .catch(() => setShowErrorMsg(true));
    }
  };

  return (
    <div className="recipient-form-container">
      <Spacing bottom="spacing4x">
        <form
          className="recipient-form"
          onSubmit={handleSubmit(formSubmit)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        >
          <PageHeading content={`${template} a recipient`} />
          <Subheading
            title="Recipient Details"
            description={`${template} a recipient of notifications regarding Telstra Dedicated Networks services.`}
            alignItems="center"
            justifyContent="space-between"
          >
            {template === "Edit" ? <Link to={"/notification-management"}>Cancel</Link> : null}
          </Subheading>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                id="name-field"
                label="Name"
                helpText="Enter the name of a person or distribution list. Spacing is allowed."
                developmentUrl={ableUrl}
                invalid={!!errors.name}
                invalidInputText={errors.name?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="mobileNumber"
            control={control}
            render={({ field }) => (
              <TextField
                id="mobile-field"
                label="Mobile number"
                helpText="Enter the mobile number that will receive notifications."
                inputHelper="AustralianMobileNumber"
                developmentUrl={ableUrl}
                invalid={!!errors.mobileNumber}
                invalidInputText={errors.mobileNumber?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                id="email-field"
                label="Email"
                helpText="Enter the email address that will receive notifications."
                developmentUrl={ableUrl}
                invalid={!!errors.email}
                invalidInputText={errors.email?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="confirmEmail"
            control={control}
            render={({ field }) => (
              <TextField
                id="confirm-email-field"
                label="Confirm Email"
                helpText="Confirm email address as above."
                developmentUrl={ableUrl}
                invalid={!!errors.confirmEmail}
                invalidInputText={errors.confirmEmail?.message}
                {...field}
              />
            )}
          />
          <br />
          <div>
            <h2>Subscription settings</h2>
            <p className="recipient-form-custom-p">Subscribe recipient to receive notifications by:</p>
          </div>
          <Controller
            name="isEmailEnabled"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch label="Email" variant="Comfortable" checked={value} events={{ onChange }} />
            )}
          />
          <Controller
            name="isSmsEnabled"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch label="SMS" variant="Comfortable" checked={value} events={{ onChange }} />
            )}
          />
          {showErrorMsg && (
            <MessageInline
              variant="Error"
              description={"Something went wrong whilst submitting the form entry, please try again later"}
              developmentUrl={ableUrl}
            />
          )}
          <div className="recipient-form-buttons">
            {template === "Create" && <Link to={"/notification-management"}>Back</Link>}
            {template === "Edit" && (
              <ActionButton
                element="button"
                variant="Destructive"
                developmentUrl={ableUrl}
                events={{ onClick: () => setShowDeleteWarning(true) }}
                deleteIcon={true}
                label="Delete"
                elementRef={deleteButtonRef}
              />
            )}
            <ActionButton
              element="button"
              label={template === "Create" ? "Submit" : "Update"}
              type="submit"
              variant="HighEmphasis"
              elementRef={submitButtonRef}
            />
          </div>
        </form>
      </Spacing>
      <NrNoSubWarningModal
        template={template}
        focusRef={submitButtonRef}
        open={showNoSubWarning}
        setOpen={setShowNoSubWarning}
        onConfirm={() =>
          formAction(getValues())
            .then(() => navigateToConfirmation())
            .catch(() => setShowErrorMsg(true))
        }
      />

      <NrDeleteWarningModal
        focusRef={deleteButtonRef}
        open={showDeleteWarning}
        setOpen={setShowDeleteWarning}
        onConfirm={() => {
          if (deleteAction) {
            deleteAction()
              .then(() => navigateToConfirmation())
              .catch(() => setShowErrorMsg(true));
          }
        }}
      />
    </div>
  );
};
