import { Column, Grid, MessageInline, Spacing } from "@able/react";
import { useQuery } from "@tanstack/react-query";
import { PageHeading } from "components/ui/PageHeading";
import { Helmet } from "react-helmet-async";
import { fetchMicaData, fetchTenancyData } from "services/api/account-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { getTokenData } from "utils/tokens";
import "./Dashboard.scss";
import CustomIllustration from "./Illustration";
import { ServiceTierCounts } from "./ServiceTierCounts";

function getGreeting(name: string) {
  let curHr = new Date().getHours();
  if (curHr < 12) return "Morning, " + name;
  if (curHr < 18) return "Afternoon, " + name;
  if (curHr < 23) return "Evening, " + name;
  else return "Hello, " + name;
}

export const DashboardContainer = () => {
  useBreadCrumbs([]);
  const { data: tenancyData, isLoading, error } = useQuery({ queryKey: ["tenant"], queryFn: fetchTenancyData });
  const { data: micaData } = useQuery({
    queryKey: ["mica"],
    queryFn: () => fetchMicaData(tenancyData[0].tenancyId),
    enabled: !!tenancyData?.[0],
  });

  // Sometimes the account api will return an empty array for the user.
  // TODO: check if the API should be returning a blank object or an error code.
  if (!isLoading && !tenancyData?.[0]) {
    return (
      <MessageInline
        variant="Error"
        description="Fetching Tenancy Data resulted in an error."
        developmentUrl="/assets/ables-sprites.svg"
      />
    );
  }

  let decodedToken = getTokenData();

  return (
    <main>
      <Helmet>
        <title>Telstra Dedicated Network</title>
      </Helmet>
      <Spacing top="spacing7x">
        <Grid>
          <Column cols={6}>
            <PageHeading content={getGreeting(decodedToken?.firstName || "user not retrieved!")} />
            {isLoading && (
              <div>
                <p className="animated-background"></p>
                <p className="animated-background"></p>
                <p className="animated-background"></p>
              </div>
            )}
            {!isLoading && (
              <div>
                {tenancyData?.[0] ? (
                  <>
                    <p className="para-light">{tenancyData[0].customerName}</p>
                    <p className="para-light">{tenancyData[0].tenancyName}</p>
                    <p className="para-light">{tenancyData[0].serviceArea[0].serviceAreaName}</p>
                  </>
                ) : null}
                {micaData ? (
                  <p className="para-bold">Customer Reference: TDN-{micaData.data.micaAccountNumber}</p>
                ) : null}
                <p className="para-useful-link">Useful links</p>
              </div>
            )}
            {error && (
              <MessageInline
                variant="Error"
                description="Fetching Tenancy Data resulted in an error."
                developmentUrl="/assets/ables-sprites.svg"
              />
            )}
          </Column>
          <Column cols={6}>
            <CustomIllustration />
          </Column>
          <Column className="grid-item--vxs-padding-bottom" cols={3} vxs={12} vsm={6} vmd={4}>
            <ServiceTierCounts />
          </Column>
        </Grid>
      </Spacing>
    </main>
  );
};
