import { ActionButton, Select, Spacing, TextField } from "@able/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { ableUrl } from "utils/constants";
import "./styles.scss";
import { type UserFormData, userFormSchema } from "./UserFormSchema";

type Props = {
  onSubmit: (data: UserFormData) => void;
};

export function UserForm({ onSubmit }: Props) {
  // Not providing a default value for mobileNumber is causing an error in the console.
  const form = useForm<UserFormData>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      role: "USER",
      email: "",
      confirmEmail: "",
      mobileNumber: "",
    },
  });

  const errors = form.formState.errors;

  return (
    <form className="flex-column-form" onSubmit={form.handleSubmit(onSubmit)}>
      <Controller
        name="firstName"
        control={form.control}
        render={({ field }) => (
          <TextField
            id="firstName-field"
            required={true}
            label="First Name*"
            helpText="Enter the users' first name."
            developmentUrl={ableUrl}
            invalid={!!errors.firstName}
            invalidInputText={errors.firstName?.message as string}
            {...field}
          />
        )}
      />
      <Controller
        name="lastName"
        control={form.control}
        render={({ field }) => (
          <TextField
            id="lastName-field"
            required={true}
            label="Last Name*"
            helpText="Enter the users' last name."
            developmentUrl={ableUrl}
            invalid={!!errors.lastName}
            invalidInputText={errors.lastName?.message as string}
            {...field}
          />
        )}
      />
      <Controller
        name="role"
        control={form.control}
        render={({ field: { onChange, ...rest } }) => (
          <Select
            id="role-field"
            label="User role*"
            helpText="Select a role for this user."
            developmentUrl={ableUrl}
            allowEmpty={false}
            options={[
              { label: "USER", value: "USER" },
              { label: "ADMIN", value: "ADMIN" },
            ]}
            invalid={!!errors.role?.message}
            events={{ onChange: onChange }}
            {...rest}
          />
        )}
      />

      <Controller
        name="email"
        control={form.control}
        render={({ field }) => (
          <TextField
            id="email-field"
            required={true}
            label="Email address*"
            helpText="Enter the users' email address."
            developmentUrl={ableUrl}
            invalid={!!errors.email}
            invalidInputText={errors.email?.message as string}
            {...field}
          />
        )}
      />
      <Controller
        name="confirmEmail"
        control={form.control}
        render={({ field }) => (
          <TextField
            id="confirmEmail-field"
            required={true}
            label="Confirm email address*"
            helpText="Enter the users' email address again for confirmation."
            developmentUrl={ableUrl}
            invalid={!!errors.confirmEmail}
            invalidInputText={errors.confirmEmail?.message as string}
            {...field}
          />
        )}
      />
      <Controller
        name="mobileNumber"
        control={form.control}
        render={({ field }) => (
          <TextField
            id="mobileNumber-field"
            label="Mobile Number"
            helpText="Enter the users' mobile number, starting with '04'."
            developmentUrl={ableUrl}
            invalid={!!errors.mobileNumber}
            invalidInputText={errors.mobileNumber?.message as string}
            {...field}
          />
        )}
      />
      <div className="form-button-group">
        <ActionButton label="Back" element="Link" href=".." variant="MediumEmphasis" />
        <ActionButton element="button" variant="HighEmphasis" label="Submit" type="submit" />
      </div>
      <Spacing bottom="spacing2x" />
    </form>
  );
}
