import { useQuery } from "@tanstack/react-query";
import { fetchUsersData } from "services/api/identity-service";

type UserQueryProps = {
  enabled?: boolean;
  fetchOnWindowRefocus?: boolean;
};

function useUserQuery(options: UserQueryProps = {}) {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsersData(),
    initialData: [],
    retry: false,
    enabled: options.enabled ?? true,
    refetchOnWindowFocus: options.fetchOnWindowRefocus ?? true,
  });
}

export default useUserQuery;
