import { z } from "zod";

export const userFormSchema = z
  .object({
    firstName: z.string().min(3, "Name must contain at least 3 characters.").max(64),
    lastName: z.string().min(1, "Must contain at least 1 digit"),
    mobileNumber: z
      .string()
      .trim()
      .refine(
        (value) => {
          const regex = new RegExp(/^(\+61|0)[2-478](\s?\d{4}){2}$/gm);
          if (value === "" || value === undefined) return true;
          if (regex.test(value)) return true;
          return false;
        },
        { path: ["mobileNumber"], message: "Please use correct AU format. Or do not provide a mobile." }
      ),
    // .regex(/^04\d{2}\s\d{3}\s\d{3}$/gm, "Please use format: 04XX XXX XXX")
    email: z.string().email(),
    confirmEmail: z.string().email(),
    role: z.enum(["USER", "ADMIN"]),
  })
  .refine((data) => data.email === data.confirmEmail, {
    path: ["confirmEmail"],
    message: "Emails do not match, please check and try again.",
  });

export type UserFormData = z.infer<typeof userFormSchema>;
