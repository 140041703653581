import axios, { AxiosError } from "axios";
import { getProperty } from "./configHelper";
import { getTokenFromSessionStorage } from "./tokens";

const baseURL = getProperty("BASE_PATH", "https://tdn-portal-dev.dedicatednetworks.telstra.com");
// const ORIGIN = getProperty("REDIRECT_URL", "http://localhost:3000");
const NO_AUTH_PATHS = [
  "/api/tdn/identity/v1/login/external",
  "/api/tdn/identity/v1/logout",
  "/api/tdn/identity/v1/token/refresh",
];

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(async (config) => {
  // TDN Authorization service handler.
  if (config.url && NO_AUTH_PATHS.includes(config.url)) {
    config.headers.set("Access-Control-Allow-Origin", "*");

    return config;
  }

  // TDN requests handler. Auth required.
  const accessToken = getTokenFromSessionStorage("id_token");
  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

// This isn't handling cancelled errors correctly
axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error instanceof AxiosError) {
    console.error(error);
    console.log("An error occurred while making an axios request: ", {
      status: error.status,
      statusText: error.response?.statusText,
      method: error.config?.method?.toUpperCase(),
      baseURL: error.config?.baseURL,
      url: error.config?.url,
      response: error.response?.data,
    });
  }

  return Promise.reject(error);
});

export default axiosInstance;
