import { MessageInline } from "@able/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PageHeading } from "components/ui/PageHeading";
import { queryClient } from "index";
import { Link, useParams } from "react-router-dom";
import { fetchUserData, updateUser } from "services/api/identity-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { User } from "types";
import { ableUrl } from "utils/constants";
import EditUserForm from "./EditUserForm";
import "./styles.scss";

export default function EditUserPage() {
  useBreadCrumbs([
    { label: "Dashboard", to: "/" },
    { label: "User management", to: "/user-management" },
  ]);

  const params = useParams();

  const { data, isLoading, isError, error } = useQuery<User>({
    queryKey: ["users", params.id],
    queryFn: () => fetchUserData(params.id as string),
    enabled: !!params.id,
  });

  const mutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: { role: string } }) => updateUser({ id, data }),
    onSuccess: (_res, vars) => queryClient.invalidateQueries({ queryKey: ["users", vars.id] }),
  });

  if (isLoading) {
    return <div>Loading user data</div>;
  }

  if (isError) {
    return (
      <MessageInline variant="Error" description={`An Error has occurred fetching the user data; ${error.message}`} />
    );
  }

  if (!data) {
    return (
      <MessageInline variant="Error" description={`No data found for user ${params.id}`} developmentUrl={ableUrl} />
    );
  }

  return (
    <main className="edit-user-page">
      <div className="flex-column gap-1rem">
        <PageHeading content="Edit user" />
        <div className="flex items-center space-between">
          <h2>User Details</h2>
          <Link to={"/user-management"}>Cancel</Link>
        </div>
        {mutation.isPending ? (
          <MessageInline variant="Information" description="Submitting request" developmentUrl={ableUrl} />
        ) : null}
        {mutation.isSuccess ? (
          <MessageInline variant="Success" description="Success!" developmentUrl={ableUrl} />
        ) : null}
        {mutation.isError ? (
          <MessageInline
            variant="Error"
            description="Something went wrong, please try again later!"
            developmentUrl={ableUrl}
          />
        ) : null}
        <EditUserForm userData={data} onSubmit={(id, data) => mutation.mutate({ id, data })} />
      </div>
    </main>
  );
}
