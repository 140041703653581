import { AxiosRequestConfig } from "axios";
import type {
  AABSectorUtilizationResp,
  SectorThroughputResp,
  ServiceAreaHistoryResp,
  SubscriptionHistoryResp,
} from "types";
import axiosInstance from "utils/axiosInstance";

type FetchServiceHistory = {
  subscriptionId: string;
  period: string;
  dateTimeFrom: string;
  dateTimeTo: string;
};

export async function fetchSubscriptionHistory({
  subscriptionId,
  period,
  dateTimeFrom,
  dateTimeTo,
}: FetchServiceHistory) {
  const response = await axiosInstance.get<SubscriptionHistoryResp>(
    `/api/tdn/observability/v1/subscriptions/${subscriptionId}/history`,
    {
      params: { period, dateTimeFrom, dateTimeTo },
    }
  );

  const data = response.data.data;
  return data;
}

// TODO: add correct return type
export async function fetchServiceAreaHistory(serviceAreaId: string, options: AxiosRequestConfig) {
  const response = await axiosInstance.get<ServiceAreaHistoryResp>(
    `/api/tdn/observability/v1/service-areas/${serviceAreaId}`,
    options
  );

  const data = response.data.data;
  return data;
}

export async function fetchAABUtilizationBySectorID(sectorId: string, dateTimeFrom: string, dateTimeTo: string) {
  const response = await axiosInstance.get<AABSectorUtilizationResp>(
    `/api/tdn/observability/v1/sectors/${sectorId}/aab`,
    { params: { dateTimeFrom, dateTimeTo } }
  );

  const data = response.data.data;
  return data;
}

type SectorThroughputParams = {
  sectorId: string;
  serviceTier: string; // refine later
  dateTimeFrom: string;
  dateTimeTo: string;
  period: string;
};

export async function fetchThroughputBySectorID({
  sectorId,
  serviceTier,
  dateTimeFrom,
  dateTimeTo,
  period,
}: SectorThroughputParams) {
  const response = await axiosInstance.get<SectorThroughputResp>(`/api/tdn/observability/v1/sectors/${sectorId}`, {
    params: { serviceTier, dateTimeFrom, dateTimeTo, period },
  });

  const data = response.data.data;
  return data;
}

export async function fetchAABUtilizationByServiceArea(
  serviceAreaId: string,
  dateTimeFrom: string,
  dateTimeTo: string
) {
  const response = await axiosInstance.get<AABSectorUtilizationResp>(
    `/api/tdn/observability/v1/service-areas/${serviceAreaId}/aab`,
    { params: { serviceTier: "low", dateTimeFrom, dateTimeTo } }
  );

  const data = response.data.data;
  return data;
}
