/**
 * Strips out any keys that have nullish values.
 * @param object
 */
export function stripNullish(object: Record<string, any>): Record<string, any> {
  let newObject: Record<string, any> = {};

  for (const [key, value] of Object.entries(object)) {
    if (value) {
      newObject[key] = value;
    }
  }

  return newObject;
}
